import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We were excited to see how many complimentary things Paybefore's recent article "Gamification: More than Just Fun and Games" had to say about PayPerks.`}</p>
    <p>{`The article explains how gamification can help consumers overcome some major barriers to trying a new financial service product—fear, distrust and lack of interest—and goes on to suggest that PayPerks' usage rewards takes gamification to a new level by incenting ongoing product utilization.`}</p>
    <p>{`Hard to believe it's been more than 2 years now since Paybefore named us their `}<a parentName="p" {...{
        "href": "https://blog.payperks.com/2011/06/15/paybefore-selects-payperks-as-this-months-company-to-watch/"
      }}>{`"Company to Watch"`}</a>{`. We are glad to see they're still watching!`}</p>
    <iframe className="scribd_iframe_embed" src="//www.scribd.com/embeds/177002323/content?start_page=1&#038;view_mode=scroll&#038;show_recommendations=true" data-auto-height="false" data-aspect-ratio="undefined" scrolling="no" id="doc_58519" width="100%" height="600" frameBorder="0"></iframe>
    <p><a parentName="p" {...{
        "href": "http://www.scribd.com/doc/177002323/Gamification-More-than-Just-Fun-and-Games"
      }}>{`Gamification: More than Just Fun and Games`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      